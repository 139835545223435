@use '@/styles/utilities/_animation' as _animation;
@use '@/styles/commons/commons' as commons;

.mv {
  @include commons.media-small {
    width: 100dvw;
    overflow: hidden;
    position: relative;
  }
}

// .back {
//   background: url('/assets/images/teaser/mv/back-circle.svg');
//   background-size: contain;

//   width: 76.8dvw;
//   aspect-ratio: 1475/1510;

//   position: absolute;
//   right: 0;
//   top: 0;
//   z-index: commons.$zIndex-teaser-mv;

//   @include commons.media-small {
//     background: url('/assets/images/teaser/mv/back-circle-sp.svg');
//     background-size: contain;
//     width: 310px;
//     right: 0;
//     top: 0;

//     aspect-ratio: 310/319;
//   }

//   &::after {
//     background: url('/assets/images/teaser/mv/back-wpc.webp');
//     background-size: contain;

//     content: '';
//     display: block;

//     width: 15.2dvw;
//     aspect-ratio: 1;

//     position: absolute;
//     right: 16.25dvw;
//     top: 18.07dvw;
//     z-index: commons.$zIndex-teaser-mv + 10;

//     @include _animation.animate(rotate, 4s, ease-out, 4s, infinite);

//     @include commons.media-small {
//       width: 128px;
//       right: 0;
//       top: 0;
//       translate: 34% -25%;
//     }
//   }
// }

// .arealevel {
//   background: url('/assets/images/teaser/mv/back-area.webp');
//   background-size: contain;

//   display: block;
//   width: 27.39vw;
//   aspect-ratio: 1;

//   position: absolute;
//   left: 5.88dvw;
//   top: 28.85dvw;
//   z-index: commons.$zIndex-teaser-mv + 20;

//   @include commons.media-small {
//     width: 134.93dvw;
//     left: 50dvw;
//     top: 50dvh;
//     translate: -50% -45%;
//   }
// }

.logo {
  background: url('/assets/images/teaser/mv/mv-logo.svg');
  background-size: contain;
  display: block;
  width: 135px;
  aspect-ratio: 135/29;

  position: absolute;
  right: 56px;
  top: 96px;
  z-index: commons.$zIndex-teaser-mv + 30;

  @include commons.media-small {
    display: none;
  }
}

.title_wrap {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(46, 183, 186, 0.3) 0%,
      rgba(46, 183, 186, 0) 100%
    );
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: commons.$zIndex-teaser-mv - 30;
  }

  @include commons.media-small {
    align-items: flex-start;
  }
}

.title {
  opacity: 0;
  @include _animation.animate(
    blink-visible,
    commons.$animation-speed-blink-view,
    ease-out,
    commons.$animation-mv-delay-1,
    forwards
  );

  width: 66.66dvw;
  aspect-ratio: 1279/171;

  position: relative;
  z-index: commons.$zIndex-teaser-mv + 50;

  &::before {
    background: url('/assets/images/teaser/mv/mv-title-cyan.svg');
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    display: block;
    width: 100%;
    aspect-ratio: 1278/170;
    position: absolute;
    left: -1px;
    top: -1px;
    z-index: commons.$zIndex-teaser-mv + 30;

    @include _animation.animate(
      glitch-cyan-pc-title,
      6s,
      ease-out,
      2s,
      infinite
    );
    transform-origin: right top;
    will-change: left, top;

    @include commons.media-small {
      background: url('/assets/images/teaser/mv/mv-sp-title-cyan.svg');
      background-size: contain;
      background-repeat: no-repeat;
      aspect-ratio: 310/69;
      left: -0.5px;
      top: -0.5px;

      @include _animation.animate(glitch-cyan-sp, 6s, ease-out, 2s, infinite);
    }
  }

  &::after {
    background: url('/assets/images/teaser/mv/mv-title-magenta.svg');
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    display: block;
    width: 100%;
    aspect-ratio: 1280/173;
    position: absolute;
    right: -1px;
    bottom: -1px;
    z-index: commons.$zIndex-teaser-mv + 40;

    @include _animation.animate(
      glitch-magenta-pc-title,
      6s,
      ease-out,
      2s,
      infinite
    );
    will-change: right, bottom;

    @include commons.media-small {
      background: url('/assets/images/teaser/mv/mv-sp-title-magenta.svg');
      background-size: contain;
      background-repeat: no-repeat;
      aspect-ratio: 310/69;
      right: -0.5px;
      bottom: -0.5px;

      @include _animation.animate(
        glitch-magenta-sp,
        6s,
        ease-out,
        2s,
        infinite
      );
    }
  }

  @include commons.media-small {
    margin-top: 90px;
    width: 82.66dvw;
    aspect-ratio: 310/69;
  }
}

.title_image {
  display: block;
  aspect-ratio: 1279/171;

  @include commons.media-small {
    display: none;
  }
}

.title_image_sp {
  display: none;
  aspect-ratio: 310/68;

  @include commons.media-small {
    display: block;
  }
}

.intro {
  opacity: 0;
  @include _animation.animate(
    blink-visible,
    commons.$animation-speed-blink-view,
    ease-out,
    commons.$animation-mv-delay-2,
    forwards
  );

  margin: 40px auto 0;
  font-size: 20px;
  font-weight: bold;
  color: #cbedee;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.15em;
  text-align: center;

  position: relative;

  z-index: commons.$zIndex-teaser-mv + 50;

  @include commons.media-small {
    margin-top: 10px;
    font-size: 10px;
  }

  // &::before {
  //   background: url('/assets/images/teaser/mv/mv-intro-cyan.svg');
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   aspect-ratio: 569/53;
  //   position: absolute;
  //   left: -0.5px;
  //   top: -0.5px;
  //   z-index: commons.$zIndex-teaser-mv + 30;

  //   transform-origin: right top;

  //   @include _animation.animate(
  //     glitch-cyan-pc-intro,
  //     4s,
  //     ease-out,
  //     3s,
  //     infinite
  //   );
  //   will-change: left, top;

  //   @include commons.media-small {
  //     background: url('/assets/images/teaser/mv/mv-sp-intro-cyan.svg');
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     aspect-ratio: 170/16;

  //     width: 100%;

  //     left: -0.05px;
  //     top: -0.05px;

  //     @include _animation.animate(glitch-cyan-sp, 4s, ease-out, 3s, infinite);
  //   }
  // }

  // &::after {
  //   background: url('/assets/images/teaser/mv/mv-intro-magenta.svg');
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   aspect-ratio: 569/53;
  //   position: absolute;
  //   right: -2.5px;
  //   bottom: -0.5px;
  //   z-index: commons.$zIndex-teaser-mv + 40;

  //   @include _animation.animate(
  //     glitch-magenta-pc-intro,
  //     4s,
  //     ease-out,
  //     3s,
  //     infinite
  //   );
  //   will-change: right, bottom;

  //   @include commons.media-small {
  //     background: url('/assets/images/teaser/mv/mv-sp-intro-magenta.svg');
  //     background-size: contain;
  //     background-repeat: no-repeat;
  //     aspect-ratio: 170/16;

  //     width: 100%;

  //     right: -0.05px;
  //     bottom: -0.05px;

  //     @include _animation.animate(
  //       glitch-magenta-sp,
  //       4s,
  //       ease-out,
  //       3s,
  //       infinite
  //     );
  //   }
  // }
}

// .intro_image {
//   width: 100%;
//   aspect-ratio: 569/53;

//   display: block;

//   @include commons.media-small {
//     display: none;
//   }
// }

// .intro_image_sp {
//   display: none;
//   aspect-ratio: 170/16;

//   @include commons.media-small {
//     display: block;
//   }
// }

.scroll_wrapper {
  inline-size: 12px;
  block-size: 100px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 30px;
  left: 50%;
  z-index: commons.$zIndex-teaser-mv + 50;

  pointer-events: all;

  cursor: pointer;

  display: none;

  @include commons.media-small {
    display: block;
  }
}

.scroll_down {
  display: inline-flex;
  inline-size: 1px;
  block-size: 100px;
  position: absolute;
  bottom: 0;

  &::before {
    animation: elasticus 2s _animation.ease('easeInCubic') infinite;

    position: absolute;
    top: 0;
    left: 0;
    inline-size: 0.5px;
    block-size: 100px;

    background-color: commons.$color-primary;
    content: '';
    display: inline-flex;
  }
}

@keyframes elasticus {
  0% {
    transform-origin: 0% 0%;
    scale: 1 0;
  }

  50% {
    transform-origin: 0% 0%;
    scale: 1 1;
  }

  50.1% {
    transform-origin: 0% 100%;
    scale: 1 1;
  }

  100% {
    transform-origin: 0% 100%;
    scale: 1 0;
  }
}

.scroll_title {
  display: inline-flex;
  color: commons.$color-primary;
  font-size: 10px;
  @include commons.text-base-ja(
    commons.$font-w-ja-medium,
    commons.$font-l_s-medium,
    commons.$font-l_h-min
  );

  position: absolute;
  left: 5px;
  top: 0;

  writing-mode: vertical-rl;
  vertical-align: bottom;
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #000;
  @include commons.media-small {
    display: none;
  }
  &:before {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video_control {
  position: absolute;
  bottom: 12%;
  right: 8%;
  z-index: 2;
  button {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        @include _animation.animate(
          blink-hover,
          commons.$animation-speed-blink,
          ease-out,
          0s,
          none
        );
      }
    }
    & + button {
      margin-left: 18px;
    }
  }
}
