@use "@/styles/utilities/_animation" as _animation;
@use "@/styles/commons/commons" as commons;

.frame_bg {
  background-color: commons.$color-black;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: commons.$zIndex-common-bg;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('/assets/images/commons/frame/pattern.png');
    opacity: 0.5;
  }
}

.frame {
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: commons.$zIndex-common-frame;
  pointer-events: none;
}

.frame_sp {
  display: none;

  @include commons.media-small {
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 100%;
    height: 100%;
  }

}

.frame_sp_top {
  width: 100%;
  aspect-ratio: 375/140;
  background: url('/assets/images/commons/frame/sp-top.svg');
  background-size: contain;
}

.frame_sp_middle {
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    background-color: commons.$color-primary;
    width: 0.5%;
    height: 100%;
    display: block;
    position: absolute;
    left: 3.5%;
    top: 0;
  }

  &:after {
    content: '';
    background-color: commons.$color-primary;
    width: 0.5%;
    height: 100%;
    display: block;
    position: absolute;
    right: 3.5%;
    top: 0;
  }
}

.frame_sp_bottom {
  width: 100%;
  aspect-ratio: 375/140;
  background: url('/assets/images/commons/frame/sp-bottom.svg');
  background-size: contain;
}

.frame_pc {
  @include commons.media-small {
    display: none;
  }
}

.edge {
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
}

.border {
  position: fixed;
}

.left_sp {
  width: 39px;
}

.left_top {
  width: 39px;
  height: 209px;
  background: url('/assets/images/commons/frame/left-top.svg');
  left: commons.$unit-s-medium-middle;
  top: commons.$unit-s-medium-middle;
}

.left_bottom {
  width: 67px;
  height: 210px;
  background: url('/assets/images/commons/frame/left-bottom.svg');
  left: commons.$unit-s-medium-middle;
  bottom: commons.$unit-s-medium-middle;
}

.left_middle {
  $left_top-height: 209px;
  $left_bottom-height: 210px;
  $left_top-left: 39px;
  background-color: commons.$color-primary;
  width: 3px;
  height: calc(100dvh - ($left_top-height + $left_bottom-height + commons.$unit-s-medium-middle * 2 - 6px));
  left: calc(commons.$unit-s-medium-middle + $left_top-left - 3.5px);
  top: calc(commons.$unit-s-medium-middle + $left_top-height - 3px);
}

.right_top {
  width: 40px;
  height: 209px;
  background: url('/assets/images/commons/frame/right-top.svg');
  right: commons.$unit-s-medium-middle;
  top: commons.$unit-s-medium-middle;


}

.right_bottom {
  width: 40px;
  height: 208px;
  background: url('/assets/images/commons/frame/right-bottom.svg');
  right: commons.$unit-s-medium-middle;
  bottom: commons.$unit-s-medium-middle;


}

.right_middle {
  $right_top-height: 209px;
  $right_bottom-height: 208px;
  $right_top-right: 39px;
  background-color: commons.$color-primary;
  width: 3px;
  height: calc(100dvh - ($right_top-height + $right_bottom-height + commons.$unit-s-medium-middle * 2 - 6px));
  right: calc(commons.$unit-s-medium-middle + $right_top-right - 2.5px);
  top: calc(commons.$unit-s-medium-middle + $right_top-height - 3px);
}

.top {
  $left_top-width: 39px;
  $right_top-width: 40px;
  background-color: commons.$color-primary;
  width: calc(100dvw - ($left_top-width + commons.$unit-s-medium-middle - 6px));
  height: 3px;
  left: calc(commons.$unit-s-medium-middle + 0.5px);
  top: calc(commons.$unit-s-medium-middle - 3px);
}

.bottom {
  $left_bottom-width: 67px;
  background-color: commons.$color-primary;
  width: calc(100dvw - ($left_bottom-width + commons.$unit-s-medium-middle * 2));
  height: 3px;
  left: calc($left_bottom-width + commons.$unit-s-medium-middle - 2px);
  bottom: calc(commons.$unit-s-medium-middle);
}

.outside {
  background: url('/assets/images/commons/frame/outside.svg');
  width: 50px;
  height: 55px;
  display: block;
  position: fixed;

  @include commons.media-small {
    display: none;
  }
}

.outside_left_top {
  left: -3px;
  top: 200px;

  @include commons.media-small {
    display: none;
  }
}

.outside_left_bottom {
  left: -3px;
  bottom: 200px;
  rotate: 180deg;
  scale: -1 1;

  @include commons.media-small {
    display: none;
  }
}

.outside_right_top {
  right: -3px;
  top: 200px;
  scale: -1 1;

  @include commons.media-small {
    display: none;
  }
}

.outside_right_bottom {
  right: -3px;
  bottom: 200px;
  rotate: 180deg;

  @include commons.media-small {
    display: none;
  }
}

.outside_left_middle {
  $left_top-height: 209px;
  $left_bottom-height: 208px;
  $left_top-left: 39px;
  background-color: commons.$color-primary;
  width: 3px;
  height: calc(100dvh - ($left_top-height + $left_bottom-height + commons.$unit-s-medium-middle * 2 + 20px));
  left: calc(commons.$unit-s-medium-middle + 11.5px);
  top: calc(commons.$unit-s-medium-middle + $left_top-height + 10px);

  @include commons.media-small {
    display: none;
  }
}

.outside_right_middle {
  $right_top-height: 209px;
  $right_bottom-height: 208px;
  $right_top-right: 39px;
  background-color: commons.$color-primary;
  width: 3px;
  height: calc(100dvh - ($right_top-height + $right_bottom-height + commons.$unit-s-medium-middle * 2 + 20px));
  right: calc(commons.$unit-s-medium-middle + 11.5px);
  top: calc(commons.$unit-s-medium-middle + $right_top-height + 10px);

  @include commons.media-small {
    display: none;
  }
}

.frame_right_top {
  background-color: commons.$color-primary;
  width: 66dvw;
  height: 10px;
  position: fixed;
  right: 0;
  top: 0;

  &:before {
    background-color: commons.$color-primary;
    content: '';
    width: 10px;
    height: 10px;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    display: block;
    position: absolute;
    left: -9px;
    top: 0;
  }

  @include commons.media-small {
    display: none;
  }
}

.frame_left_bottom {
  background-color: commons.$color-primary;
  width: 42dvw;
  height: 10px;
  position: fixed;
  left: 0;
  bottom: 0;

  &:before {
    background-color: commons.$color-primary;
    content: '';
    width: 10px;
    height: 10px;
    clip-path: polygon(100% 100%, 0 0, 0 100%);
    display: block;
    position: absolute;
    right: -9px;
    top: 0;
  }

  @include commons.media-small {
    display: none;
  }
}

.frame_to_top {
  width: 66px;
  height: 67px;
  position: fixed;
  right: 20px;
  bottom: 0;
  z-index: commons.$zIndex-common-fixed;

  @include commons.media-small {
    display: none;
  }
}

.backlight_left {
  position: fixed;
  background: linear-gradient(-90deg, rgba(46, 183, 186, 0.00) 0%, rgba(39, 169, 172, 0.20) 100%);
  width: 100px;
  height: 100dvh;
  left: 0;
  top: 0;
  z-index: -2;
}

.backlight_right {
  position: fixed;
  background: linear-gradient(90deg, rgba(46, 183, 186, 0.00) 0%, rgba(39, 169, 172, 0.20) 100%);
  width: 100px;
  height: 100dvh;
  right: 0;
  top: 0;
}

.backlight_top {
  position: fixed;
  background: linear-gradient(0deg, rgba(46, 183, 186, 0.00) 0%, rgba(39, 169, 172, 0.20) 100%);
  width: 100dvw;
  height: 100px;
  left: 0;
  top: 0;
}

.backlight_bottom {
  position: fixed;
  background: linear-gradient(180deg, rgba(46, 183, 186, 0.00) 0%, rgba(39, 169, 172, 0.20) 100%);
  width: 100dvw;
  height: 100px;
  left: 0;
  bottom: 0;
}



.back {
  background: url('/assets/images/teaser/mv/back-circle.svg');
  background-size: contain;

  width: 76.8dvw;
  aspect-ratio: 1475/1510;

  position: fixed;
  right: 0;
  top: 0;
  z-index: commons.$zIndex-teaser-mv;

  @include commons.media-small {
    background: url('/assets/images/teaser/mv/back-circle-sp.svg');
    background-size: contain;
    width: 310px;
    right: 0;
    top: 0;

    aspect-ratio: 310/319;
  }

  &::after {
    background: url('/assets/images/teaser/mv/back-wpc.webp');
    background-size: contain;

    content: '';
    display: block;

    width: 15.2dvw;
    aspect-ratio: 1;

    position: fixed;
    right: 16.25dvw;
    top: 18.07dvw;
    z-index: commons.$zIndex-teaser-mv + 10;

    @include _animation.animate(rotate, 4s, ease-out, 4s, infinite);

    @include commons.media-small {
      width: 128px;
      right: 0;
      top: 0;
      translate: 34% -25%;
    }
  }
}

.arealevel {
  background: url('/assets/images/teaser/mv/back-area.webp');
  background-size: contain;

  display: block;
  width: 27.39vw;
  aspect-ratio: 1;

  position: fixed;
  left: 5.88dvw;
  top: 28.85dvw;
  z-index: commons.$zIndex-teaser-mv + 20;

  @include commons.media-small {
    width: 134.93dvw;
    left: 50dvw;
    top: 50dvh;
    translate: -50% -45%;
  }
}