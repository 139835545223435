@use '@/styles/utilities/_animation' as _animation;
@use '@/styles/utilities/_size' as _size;
@use '@/styles/commons/commons' as commons;

.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: commons.$zIndex-common-header;
}

.logo_wrap {
  margin-left: 56px;
  margin-top: 56px;
  width: 16.66dvw;

  @include commons.media-small {
    margin-left: 22px;
    margin-top: 23px;
    width: 120px;
  }
}

.logo {
  width: 100%;
  aspect-ratio: 320/60;
  @include commons.media-small {
    aspect-ratio: 120/27;
  }
}

.nav {
  background-color: commons.$color-primary;
  // width: 1000px;
  // height: 80px;
  padding-top: _size.between(
    5px,
    10px,
    commons.$media-i-medium,
    commons.$media-i-max
  );
  padding-bottom: _size.between(
    5px,
    10px,
    commons.$media-i-medium,
    commons.$media-i-max
  );
  padding-right: 32px;

  position: fixed;
  right: 0;
  top: 0;

  &:before {
    background-color: transparent;
    background-image: url('/assets/images/commons/header/nav-edge.svg');
    content: '';
    background-size: cover;
    aspect-ratio: 21/80;
    // height: 80px;
    // height: _size.between(40px, 80px, commons.$media-i-large, commons.$media-i-max);
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-95%);
  }

  @include commons.media-small {
    display: none;
  }
}

.list {
  display: flex;

  align-items: center;
  gap: 4px;
  height: 100%;
  // width: _size.between(600px, 1000px, commons.$media-i-medium, commons.$media-i-max);

  display: grid;
  grid-auto-flow: column;

  grid-template-columns: auto;
  margin-right: -10px;
}

.list_item {
  // width: fit-content;
  // block-size: fit-content;
  // inline-size: fit-content;

  &:first-child {
    margin-right: -10px;
  }

  &.discord {
    margin-left: -10px;
    margin-right: -10px;
  }

  // &.alpha_u_market {
  //   margin-right: -20px;
  // }

  // &.plt_place {
  //   margin-right: -20px;
  // }

  &.alpha_u_market {
    width: 130px;
  }

  &.plt_place {
    width: 130px;
    margin-left: -8px;
  }

  &.connect {
    margin: 0 -4px 0 -8px;
  }

  // &.document {
  //   margin-left: -10px;
  // }
}

.list_item_link {
  display: flex;
  align-items: center;
  height: 62px;

  @include commons.media-small {
    height: auto;
  }

  @media (hover: hover) {
    &:hover {
      @include _animation.animate(
        blink-hover,
        commons.$animation-speed-blink,
        ease-out,
        0s,
        none
      );
    }
  }

  // &.alpha_u_market {
  //   width: 120%;
  // }

  // &.plt_place {
  //   width: 120%;
  // }
}

.header_sp_nav_block {
  visibility: hidden;
  pointer-events: none;
  block-size: 0;

  opacity: 0;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;

  &.is-open {
    @include commons.media-small {
      opacity: 1;
      height: 0;

      visibility: visible;
      pointer-events: all;
      background-color: commons.$color-white;

      inline-size: 100dvw;
      block-size: 100dvh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: commons.$zIndex-sp-menu;
    }
  }
}

.header_sp_nav_content {
  position: relative;
  width: calc(100% - 60px);
  height: calc(100% - 80px);
  margin: 0 30px;
  z-index: commons.$zIndex-sp-menu + 20;
}

.header_sp_nav_logo_wrap {
  @include commons.media-small {
    object-fit: contain;
    aspect-ratio: 314/99;
    width: 82.66dvw;
    margin: 80px auto 0;
  }
}

.header_sp_nav_logo_text {
  @include commons.media-small {
    font-size: 10px;
    text-align: center;
    margin-top: 10px;
  }
}

.nav_sp {
  @include commons.media-small {
    width: 100%;
    grid-area: auto;
    order: 2;
  }
}

.list_sp {
  display: flex;
  align-items: center;

  @include commons.media-small {
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
  }
}

.list_sp_out_link {
  display: grid;
  width: 240px;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 0;
}

.list_icon {
  display: flex;
  align-items: center;
  width: _size.between(
    64px,
    96px,
    commons.$media-i-medium,
    commons.$media-i-max
  );
}

.list_item_sp_row {
  display: flex;
  align-items: center;
  width: 100%;

  margin-top: -10px;

  &:not(:first-of-type) {
  }
}

.list_item_sp.wallet,
.list_item_sp.document {
  margin-top: -10px;
}

// .wallet_hover {
//   &:hover {
//     cursor: default; // ティザー用
//   }
// }

.list_icon {
  @include commons.media-small {
    width: 100%;
    justify-content: center;
    margin-top: 0;
  }
}

.list_item_sp_icon {
  display: flex;
  align-items: center;
}

.list_item_sp_link {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.sp_link_official {
  width: 240px;
  margin: 0 auto;
}

.frame_sp {
  display: none;

  @include commons.media-small {
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: commons.$zIndex-common-frame;
    pointer-events: none;
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 100%;
    height: 100%;
    background-color: commons.$color-primary;

    &:before {
      content: '';
      background: url('/assets/images/commons/frame/sp-menu-back.webp');
      background-size: contain;
      width: 336px;
      height: 576px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      translate: 0 -50%;
      z-index: 2;
    }
  }
}

.logo_menu_bottom {
  background: url('/assets/images/commons/header/logo-menu-sp.webp');
  background-size: contain;
  display: block;
  width: 79px;
  aspect-ratio: 79/17;
  margin: 20px auto 0;
}

.frame_sp_top {
  width: 100%;
  aspect-ratio: 375/140;
  background: url('/assets/images/commons/frame/sp-menu.svg');
  background-size: contain;
  z-index: 10;
}

.frame_sp_middle {
  width: 100%;
  height: 100%;
  position: relative;

  z-index: 10;

  &:before {
    content: '';
    background-color: commons.$color-primary;
    width: 0.5%;
    height: 100%;
    display: block;
    position: absolute;
    left: 3.5%;
    top: 0;
  }

  &:after {
    content: '';
    background-color: commons.$color-primary;
    width: 0.5%;
    height: 100%;
    display: block;
    position: absolute;
    right: 3.5%;
    top: 0;
  }
}

.frame_sp_middle_black_left {
  background-color: commons.$color-black;
  width: 3.5%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.frame_sp_middle_black_right {
  background-color: commons.$color-black;
  width: 3.5%;
  height: 100%;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.frame_sp_bottom {
  width: 100%;
  aspect-ratio: 375/140;
  background: url('/assets/images/commons/frame/sp-menu.svg');
  background-size: contain;
  scale: -1 -1;
  z-index: 10;
}

.header_menu_button {
  display: none;

  @include commons.media-small {
    display: block;
    width: 60px;
    height: 30px;
    cursor: pointer;
    padding: 12px;
    position: fixed;
    top: 10px;
    right: 30px;
    z-index: commons.$zIndex-sp-menu-button;
  }
}

.header_menu_button_line {
  background-color: commons.$color-primary;
  width: 50px;
  height: 2px;
  position: absolute;
  right: 0;
  top: 18px;
  display: block;
  transition: transform 200ms ease-out, background-color 200ms ease-out;
}

.header_menu_button_line::before,
.header_menu_button_line::after {
  content: '';
  background-color: commons.$color-primary;
  width: 50px;
  height: 2px;
  position: absolute;
  left: 0px;
  display: block;
  transition: transform 200ms ease-out;
}

.header_menu_button_line::before {
  left: 10px;
  top: 0;
  transform-origin: center;
  transform: translateY(8px) rotate(0);
}

.header_menu_button_line::after {
  left: -10px;
  bottom: 0;
  transform-origin: center;
  transform: translateY(-8px) rotate(0);
}

.header_menu_button.is-open .header_menu_button_line {
  background-color: commons.$color-black;
  background-color: rgba(0, 0, 0, 0);
}

.header_menu_button.is-open .header_menu_button_line::before {
  transform: translateX(-10px) translateY(0) rotate(-30deg);
  background-color: commons.$color-black;
}

.header_menu_button.is-open .header_menu_button_line::after {
  transform: translateX(10px) translateY(0) rotate(30deg);
  background-color: commons.$color-black;
}
