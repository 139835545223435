@use '@/styles/utilities/_animation' as _animation;
@use '@/styles/utilities/_size' as _size;
@use '@/styles/commons/commons' as commons;

.footer {
  margin-top: 400px;
  margin-bottom: 70px;

  @media (max-width: 1096px) {
    margin-top: 200px;
    margin-left: 30px;
    margin-right: 30px;
  }

  &.is-mypage {
    margin-top: 100px;

    @media (max-width: 1096px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.wrap {
  display: block;
  background-color: commons.$color-primary;
  width: calc(100% - 55px);
  height: 200px;
  padding-left: 20px;
  padding-right: 75px;
  margin-left: 55px;
  position: relative;

  @media (max-width: 1096px) {
    width: 100%;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
  }

  &.is-mypage {
    width: calc(100% - 55px - 40px);
    margin-left: auto;
    margin-right: 0;

    @media (max-width: 1096px) {
      width: 100%;
      margin-bottom: 100px;
    }
  }

  &:before {
    background: url('/assets/images/commons/footer/footer-edge.svg');
    background-size: cover;
    content: '';
    width: 55px;
    height: 100%;
    display: block;
    position: absolute;
    left: -55px;
    top: 0;
    z-index: 1;

    @media (max-width: 1096px) {
      background-color: commons.$color-primary;
      content: '';
      width: 30px;
      height: 30px;
      clip-path: polygon(100% 0, 0 0, 100% 100%);
      display: block;
      position: absolute;
      left: 0;
      bottom: -29px;
      top: initial;
    }
  }

  &:after {
    @media (max-width: 1096px) {
      background-color: commons.$color-primary;
      content: '';
      width: calc(100% - 29px);
      height: 30px;
      display: block;
      position: absolute;
      right: 0;
      bottom: -29px;
    }
  }
}

.wrap_content {
  padding-top: 40px;
  display: grid;
  grid-template:
    'nav logo legal_info_nav .' auto /
    1fr auto 1fr;

  @media (max-width: 1096px) {
    grid-auto-flow: row;
    grid-template: none;
    width: 100%;
    padding-top: 50px;
  }
}

.logo_wrap {
  grid-area: logo;
  align-self: start;
  width: _size.between(
    104px,
    313px,
    commons.$media-i-medium,
    commons.$media-i-max
  );

  @media (max-width: 1096px) {
    width: 100%;
    grid-area: auto;
    order: 1;
  }
}

.logo {
  aspect-ratio: 320/60;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 1096px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.nav {
  grid-area: nav;
  justify-self: start;

  @media (max-width: 1096px) {
    width: 100%;
    grid-area: auto;
    order: 2;
  }
}

.list {
  display: flex;
  align-items: center;
  width: _size.between(
    230px,
    460px,
    commons.$media-i-medium,
    commons.$media-i-max
  );

  @media (max-width: 1096px) {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
  }
}

.list_icon {
  display: flex;
  align-items: center;
  width: _size.between(
    64px,
    96px,
    commons.$media-i-medium,
    commons.$media-i-max
  );
}

.list_item {
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    &:before {
      content: '/';
      vertical-align: middle;

      @media (max-width: 1096px) {
        display: none;
      }
    }
  }
}

.list_icon {
  @media (max-width: 1096px) {
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
}

.list_item_icon {
  display: flex;
  align-items: center;

  @media (max-width: 1096px) {
    // justify-content: center;
  }
}

.list_item_link {
  display: inline-block;

  @media (hover: hover) {
    &:hover {
      @include _animation.animate(
        blink-hover,
        commons.$animation-speed-blink,
        ease-out,
        0s,
        none
      );
    }
  }
}

.legal_info_nav {
  grid-area: legal_info_nav;
  justify-self: start;

  @media (max-width: 1096px) {
    width: 100%;
    grid-area: auto;
    order: 2;
  }
}

.legal_info_list {
  display: flex;
  align-items: center;
  justify-content: right;
  width: _size.between(
    200px,
    460px,
    commons.$media-i-medium,
    commons.$media-i-max
  );

  @media (max-width: 1096px) {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
  }
}

.legal_info_list_item {
  display: flex;
  font-size: commons.$font-s-min;

  &:not(:first-of-type) {
    &:before {
      content: '/';
      vertical-align: middle;

      @media (max-width: 1096px) {
        display: none;
      }
    }
  }
}

.legal_info_list_item_link {
  display: inline-block;
  font-size: commons.$font-s-min;

  @media (hover: hover) {
    &:hover {
      @include _animation.animate(
        blink-hover,
        commons.$animation-speed-blink,
        ease-out,
        0s,
        none
      );
    }
    @include commons.text-base-ja(
      commons.$font-w-ja-medium,
      commons.$font-l_s-min,
      commons.$font-l_h-medium
    );
  }
}

.note {
  color: commons.$color-black;
  font-size: commons.$font-s-min;
  @include commons.text-base-ja(
    commons.$font-w-ja-medium,
    commons.$font-l_s-min,
    commons.$font-l_h-medium
  );
  text-align: center;
  margin-top: 40px;
}

.copyright {
  color: commons.$color-black;
  font-size: commons.$font-s-min;
  @include commons.text-base-ja(
    commons.$font-w-ja-medium,
    commons.$font-l_s-min,
    commons.$font-l_h-medium
  );
  text-align: center;
  margin-top: 4px;
  display: block;
}
