@use "@/styles/commons/commons" as commons;

.teaser_content {
  margin-left: 110px;
  margin-right: 110px;

  @include commons.media-small {
    margin-left: 30px;
    margin-right: 30px;
  }
}