@use '@/styles/utilities/_animation' as _animation;
@use '@/styles/commons/commons' as commons;

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  scroll-margin-top: 40dvh;
  width: 60vw;
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 80vh;
  left: 50%;
  transform: translateX(-50%);
  padding: 22px 24px;
}

.about_title {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border-bottom: 1px solid #27A9AC;
  padding-bottom: 10px;
}

.about_text {
  padding-top: 22px;
  font-size: 1rem;
  font-weight: 400;
  a {
    color: blue;
    border-bottom: 1px solid blue;
  }
}

@media (max-width: 768px) {
  .about {
    top: 36vh;
    width: 80vw;
  }
  .about_title {
    font-size: 1.1rem;
  }
  .about_text {
    font-size: 0.9rem;
  }
}
