$color-primary: #27A9AC;
$color-primary-light: #91F8FB;
$color-primary-dark: #056C6F;

$color-dominator-paralyzer: #CCCCCC;
$color-dominator-eliminator: #DFD268;
$color-dominator-decomposer: #ECBF96;

$color-cyan: #00A0E9;
$color-magenta: #E4007F;

$color-white: #ffffff;
$color-black: #030303;