@charset "utf-8";
@use 'sass:math';
@use '@/styles/utilities/_size' as _size;

$media-i-small: 375px;
$media-i-medium: 768px;
$media-i-large: 1120px;
$media-i-max: 1920px;

$content-i-large: 1080px;
$content-i-max: 1700px;
$content-pc: _size.between(
  $media-i-large,
  $content-i-max,
  $media-i-large,
  $media-i-max
);

@mixin media-small {
  @media (width < $media-i-medium) {
    @content;
  }
}

@mixin media-medium {
  @media ($media-i-medium <= width < $media-i-large) {
    @media ($media-i-medium <= width) {
      @content;
    }
  }
}

@mixin media-large {
  @media ($media-i-large <=width) {
    @content;
  }
}

// @mixin media-max {
//   @media (_breakpoint.$media-width-max <=width) {
//     @content;
//   }
// }

$c-margin-small: 24px;
$c-margin-large: 64px;
$c-margin-medium: _size.between(
  $c-margin-small,
  $c-margin-large,
  $media-i-medium,
  $media-i-large
);

// 8 * 8 = 64 * 5 = 320 + (4 * 6) = 368 (m24, vw = 392)
$c-i-grid-small: 368px;

// 8 * 8 = 64 * 11 = 704 + (4 * 12) = 752 (m24-64, vw = 800)
$c-i-grid-medium: 700px;

// 8 * 8 = 64 * 11 = 704 + (8 * 3 * 12) = 992 (m64, vw = 1120)
$c-i-grid-large: 992px;

// 8 * 8 = 64 * 11 = 704 + (8 * 8 * 12) = 1472 (m64, vw = 1600)
$c-i-grid-max: 1472px;

$unit-s-min_h: 4px;
$unit-s-min: 8px;
$unit-s-min-middle: 12px;
$unit-s-small: 16px;
$unit-s-small-middle: 20px;
$unit-s-medium: 24px;
$unit-s-medium-middle: 32px;
$unit-s-large: 40px;
$unit-s-large-middle: 52px;
$unit-s-max: 64px;
